// BASE_URL config
const dev = 'surveyapp.appiness.cc';
// const prod = 'surveyapp.appiness.cc';

export const BASE_URL = `https://${dev}/api`;
export const login = `/admin/login/`;
export const getHospitalslist = `/admin/hospitals/`;
export const addVolunteer = `/admin/add-user/`;
export const downloadReport = `/admin/export/`;
export const getDashboardDetails = `/admin/dashboard/`;
export const getUsersearch = `/admin/user-search/`
export const editUpdateSurgery = `/admin/update-discharge/`;

// GET API config
export const get = async (url, token, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			 Authorization: token,
			'Content-Type': 'application/json',
		},
	})
		.then(response => response.json())
		// .then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('GET_FAILED', url, error)
			return handler(null, error)
		})
}

// POST API config for login
export const post_login = async (url, body, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			// Authorization: token,
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(body),
	})
		.then(response => response.json())
		// .then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('POST_FAILED', url, error)
			return handler(null, error)
		})
}

// POST API config
export const post = async (url, body, token, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: token,
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(body),
	})
		.then(response => response.json())
		// .then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('POST_FAILED', url, error)
			return handler(null, error)
		})
}

// PUT API config
export const put = async (url, body, token, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: token,
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify(body),
	})
		.then(response => response.json())
		// .then(handleUnauthorizedUser)
		.then(response => handler(response, null))
		.catch(error => {
			console.log('PUT_FAILED', url, error)
			return handler(null, error)
		})
}

// DELETE API config
export const delet_e = async (url, token, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: token,
			'Content-Type': 'application/json',
		},
		method: 'DELETE',
	})
		.then(response => response.json())
		// .then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('DELETE_FAILED', url, error)
			return handler(null, error)
		})
}